// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registrationFinish_title__O\\+TNb {
  padding-left: 30px;
  padding-top: 20px;
}

.registrationFinish_content__vF4Wa {
  padding-bottom: 10px;
}

.registrationFinish_right__j54SM {
  float: right;
}`, "",{"version":3,"sources":["webpack://./src/components/RegistrationFinish/registrationFinish.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".title{\n  padding-left: 30px;\n  padding-top: 20px;\n}\n\n.content{\n  padding-bottom: 10px;\n}\n\n.right{\n  float: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `registrationFinish_title__O+TNb`,
	"content": `registrationFinish_content__vF4Wa`,
	"right": `registrationFinish_right__j54SM`
};
export default ___CSS_LOADER_EXPORT___;
