// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profileModify_profileModify__xIZck {
  margin: 20px;
  text-align: left;
}

.profileModify_title__mMRCi {
  padding-left: 30px;
  padding-top: 20px;
}

.profileModify_content__kcwHk {
  margin: 10px;
  padding: 20px;
  padding-top: 0;
}

.profileModify_link__HYwTO {
  margin-left: 20px;
  margin-right: 20px;
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.profileModify_logo__Upv7v {
  margin-left: 0;
  margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/ProfileModify/profileModify.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,iBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,cAAA;AACF;;AAEA;EACE,iBAAA;EACA,kBAAA;EACA,qBAAA;EACA,YAAA;EACA,eAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;AACF","sourcesContent":[".profileModify{\n  margin: 20px;\n  text-align: left;\n}\n\n.title{\n  padding-left: 30px;\n  padding-top: 20px;\n}\n\n.content{\n  margin: 10px;\n  padding: 20px;\n  padding-top: 0;\n}\n\n.link{\n  margin-left: 20px;\n  margin-right: 20px;\n  text-decoration: none;\n  color: white;\n  cursor: pointer;\n}\n\n.logo{\n  margin-left: 0;\n  margin-right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profileModify": `profileModify_profileModify__xIZck`,
	"title": `profileModify_title__mMRCi`,
	"content": `profileModify_content__kcwHk`,
	"link": `profileModify_link__HYwTO`,
	"logo": `profileModify_logo__Upv7v`
};
export default ___CSS_LOADER_EXPORT___;
