import React, { useState } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  Typography,
  TextField,
  Button,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '400px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

const AddShiftModal = ({ open, handleClose, employees, onSave }) => {
  const classes = useStyles();
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [clockIn, setClockIn] = useState(null);
  const [clockOut, setClockOut] = useState(null);

  const resetState = () => {
    setSelectedEmployee('')
    setClockIn(null)
    setClockOut(null)
  }
  const handleSave = () => {
    if (selectedEmployee && clockIn && clockOut) {
      onSave({
        employeeId: selectedEmployee,
        clockIn,
        clockOut,
      });
      resetState()
      handleClose();
    } else {
      alert('Please fill in all fields');
    }
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          <Typography variant="h5" gutterBottom>
            Add Employee Shift
          </Typography>
          <form className={classes.form}>
            <FormControl fullWidth>
              <InputLabel id="employee-select-label">Employee</InputLabel>
              <Select
                labelId="employee-select-label"
                value={selectedEmployee}
                onChange={(e) => setSelectedEmployee(e.target.value)}
              >
                {employees.map((employee) => (
                  <MenuItem key={employee._id} value={employee._id}>
                    {employee.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                label="Clock-in Time"
                value={clockIn}
                onChange={setClockIn}
                fullWidth
              />
              <DateTimePicker
                label="Clock-out Time"
                value={clockOut}
                onChange={setClockOut}
                fullWidth
              />
            </MuiPickersUtilsProvider>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save Shift
            </Button>
          </form>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default AddShiftModal;