// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_login__SG\\+8w {
  margin: 20px;
  text-align: left;
}

.login_title__V-yKH {
  padding-left: 30px;
  padding-top: 20px;
}

.login_content__8dsuA {
  margin: 10px;
  padding: 20px;
  padding-top: 0;
}

.login_logo__r\\+qkX {
  margin-left: 0;
  margin-right: 0;
}

.login_space__0T8Da {
  margin-right: 5px;
}

.login_google__i-QTs {
  margin-top: 20px !important;
}

.login_github__7Y1HI {
  margin-top: 10px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Login/login.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,iBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,cAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,2BAAA;AACF;;AAEA;EACE,2BAAA;AACF","sourcesContent":[".login{\n  margin: 20px;\n  text-align: left;\n}\n\n.title{\n  padding-left: 30px;\n  padding-top: 20px;\n}\n\n.content{\n  margin: 10px;\n  padding: 20px;\n  padding-top: 0;\n}\n\n.logo{\n  margin-left: 0;\n  margin-right: 0;\n}\n\n.space{\n  margin-right: 5px;\n}\n\n.google{\n  margin-top: 20px !important;\n}\n\n.github{\n  margin-top: 10px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": `login_login__SG+8w`,
	"title": `login_title__V-yKH`,
	"content": `login_content__8dsuA`,
	"logo": `login_logo__r+qkX`,
	"space": `login_space__0T8Da`,
	"google": `login_google__i-QTs`,
	"github": `login_github__7Y1HI`
};
export default ___CSS_LOADER_EXPORT___;
