import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { makeStyles, fade, withStyles } from '@material-ui/core/styles';
import { setCrew } from '../../actions/connectionActions'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import moment from 'moment'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress }  from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import EventIcon from '@material-ui/icons/Event';
import ThumbsUp from '@material-ui/icons/ThumbUpAltSharp';
import TimeEditModal from '../DailyRoster/TimeEditModal'
import ApprovingModal from './ApprovingModal'
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import LinearProgress from '@material-ui/core/LinearProgress';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddShiftModal from './AddShiftModal'

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#779ca4',
  },
}))(LinearProgress);

const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  margin: 5px;
`;

const FilterColumnContainer = styled(Box)`
  display: flex;
  min-height: 85vh;
  min-width: 200px;
  padding: 10px;
  margin: 5px;
  background-color: #fff;
  border-radius: 10px;
  justify-content: center;
`;

const FilterColumn = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const FilterTitle = styled.div`
  color: #444;
  font-size: 18px;
`;

const FilterData = styled.div`
  color: #333;
  font-size: 14px;
  margin-top: 20px;
`;

const MainDataView = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px;
  margin: 5px;
  overflow: wrap;
  background-color: #fff;
  border-radius: 10px;
  cursor: pointer;
`;

const ShiftLine = styled(TableRow)`
  padding: 8px;
  background-color: ${props => (
    props.even ? '#ddd' : '#fff'
  )};
  &:hover {
    border: 3px solid rgba(222,122,34.2);
    background-color: rgba(222,122,34.1);
  }
`;

const ShiftCell = styled(TableCell)`
  padding: 8px;
`;


function desc(a, b, orderBy, isDate) {
  // console.log('desc: ', a)
  // console.log('desc b: ', b)
  if (isDate) {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      // console.log('B is < A')
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      // console.log('B is > A')
      return 1;
    }
    // console.log('B is ? A')
    // console.log('A: ', a[orderBy])
    // console.log('B: ', b[orderBy])
    return 0;
  } else {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
  // console.log('Stable sort: ', array.length)
  // console.log('CMP: ', cmp)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // console.log('Matches: ', stabilizedThis.length)
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy==='lastUpdated') {
  // console.log('Last Updated')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
  } else {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
}

function currencyFormat(num) {
  if (!num) num = 0
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const headCells = [
  { id: 'employeeName', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'created_at', numeric: false, disablePadding: false, label: 'Date' },
  { id: 'checkIn', numeric: false, disablePadding: false, label: 'Check In' },
  { id: 'clockIn', numeric: false, disablePadding: false, label: 'Clock In' },
  { id: 'clockOut', numeric: false, disablePadding: false, label: 'Clock Out' },
  { id: 'hoursWorked', numeric: false, disablePadding: false, label: 'Hours Worked' },
  { id: 'hoursWorkedVsClockedIn', numeric: false, disablePadding: false, label: '% Accounted For' },
  { id: 'approved', numeric: false, disablePadding: false, label: 'Approve' },
  { id: 'archived', numeric: false, disablePadding: false, label: 'Archive' },
];

function EnhancedTableHead(props) {
// console.log('Got our props', props)
  
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
  // console.log('Sort Property: ', property)
  // console.log('sort event; ', event)
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all leads' }}
          />
        </TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const Timesheet = (props) => {
// console.log('Time Sheet: ', props)
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)
  const [timesheets, setTimesheets] = React.useState([])
  const [timeToEdit, setTimeToEdit] = React.useState({})
  const [timeEditOpen, setTimeEditOpen] = React.useState(false)
  const [approvalModalOpen, setApprovalModalOpen] = React.useState(false)
  const [itemToApprove, setItemToApprove] = React.useState({})
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const dense = true
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [snackMessage, setSnackMessage] = React.useState('')
  const [state, setState] = React.useState({
    position: {},
    startingDate: null,
    endingDate: null
  })
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const formatDate = (date) => {
    let dd = String(date.getDate()).padStart(2, '0')
    let mm = String(date.getMonth() + 1).padStart(2, '0') // January is 0!
    let yyyy = date.getFullYear()
    return `${mm}/${dd}`
  }

  const fetchData = async () => {
    setLoading(true)
    const response = await axios.get(`${props.url}/api/crews/timesheet`)
    if (response.data && response.data.length > 0) {
    // console.log('Got Timesheet!!', response.data)
      if (response && response.data && response.data.length) setTimesheets(response.data)
    } else {
    // console.log('Fucked up')
    }
    console.log('Get Time Sheets For Approval');
    const today = new Date();

    const dayOfWeek = today.getDay();
  
    let thisWednesday;
  
    if (dayOfWeek >= 3) { // If today is Wednesday or later
      // Set this Wednesday to the current week's Wednesday
      thisWednesday = new Date(today);
      thisWednesday.setDate(today.getDate() - (dayOfWeek - 3));
    } else { // If today is before Wednesday
      // Set this Wednesday to the previous week's Wednesday
      thisWednesday = new Date(today);
      thisWednesday.setDate(today.getDate() - (dayOfWeek + 4));
    }
  
    // Calculate last Thursday (6 days before this Wednesday)
    const lastThursday = new Date(thisWednesday);
    lastThursday.setDate(thisWednesday.getDate() - 6);
  
    // ... [Your existing formatDate function] ...
  
    lastThursday.setHours(0, 0, 0, 0);
    thisWednesday.setHours(23, 59, 59, 999); // Include the whole day of Wednesday
  
    console.log('Last Thursday:', lastThursday);
    console.log('This Wednesday:', thisWednesday);
  
    const startingDate = formatDate(lastThursday);
    const endingDate = formatDate(thisWednesday);
  
    console.log('Starting Date:', startingDate);
    console.log('Ending Date:', endingDate);
    setLoading(false)
    setState({...state, startingDate, endingDate })
  }
  React.useEffect(() => {
  // console.log('Get Timesheet Data')
    fetchData()
  // console.log('LOcation Stuff')
    // if (navigator.geolocation) {
    // // console.log('Geolocation Allowed', navigator.geolocation)
    //   navigator.geolocation.getCurrentPosition(function(position) {
    //   // console.log('Position:', position.coords)
    //   // console.log("Latitude is :", position.coords.latitude);
    //   // console.log("Longitude is :", position.coords.longitude);
    //     setState({...state, position: position.coords})
    //   });
    // }
    if (props.position) {
      const ourPos = {
        latitude: props.position.latitude,
        longitude: props.position.longitude
      }
      setState({...state, position: ourPos })
    }
  }, [])

  const timeFormat = (d) => {
    return moment(d).format('hh:mmA')
  }

  function hoursRounded(d) {
    return (Math.round(d * 4) / 4).toFixed(2)
  }

  const handleEditRow = (row, index) => {
    setTimeToEdit(row)
    setTimeEditOpen(true)
  }

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    // console.log('Set order by property: ' + property + ' in ' + isDesc + ' order')
    setOrderBy(property);
  };

  const updateFromTimeEdit = (updateType, updatedShift) => {
    setTimeEditOpen(false)
    if(updateType ==='saved') {
      let sheets = [...timesheets]
      const index = sheets.findIndex(item => (item._id.toString() === updatedShift._id.toString()))
      if (index > -1) {
        sheets[index] = updatedShift
        setTimesheets(sheets)
      }
      
    }
  }

  function checkIfEven(num) {
    // console.log('Is even?', num)
    const isEven = num % 2
    // console.log(isEven)
    return isEven
  }

  const handleArchive = (item) => {
    console.log('Archive this:', item)
    let msg = `Are you sure you wish to delete this shift? This is not reversible`
    if (window.confirm(msg)) archiveIt(item)
  }

  const archiveIt = async (itemToArchive) => {
    // console.log('Arhcive', item)
    setSnackMessage(`Archived ${itemToArchive.hoursWorked} hours for ${itemToArchive.employee.displayName}`)
    setApprovalModalOpen(true)
    setItemToApprove(itemToArchive)
    let sheets = [...timesheets]
    const index = sheets.findIndex(item => (item._id.toString() === itemToArchive._id.toString()))
    if (index > -1){
      sheets.splice(index, 1)
      setTimesheets(sheets)
    } 

    axios({
      method: 'post',
      url: `${props.url}/api/employees/timesheetApproval`,
      data: {
        item: itemToArchive,
        type: 'archive'
      }
    })
      .then(({ data }) => {
        // console.log(data)
        // setLeads(data)
      }).catch(function (err) {
        // console.log('Error Undoing this', err)
      });
  }
  
  const handleApproveItem = (item) => {
    // Show snackbar
    // console.log('Approve: ', item)
    setSnackMessage(`Approved ${item.hoursWorked} hours for ${item.employee.displayName}`)
    let sheets = [...timesheets]
    console.log(sheets)
    const index = sheets.findIndex(item => (item?._id?.toString() === itemToApprove?._id?.toString()))
    if (index > -1) sheets.splice(index, 1)
    setTimesheets(sheets)
    setSnackMessage(`Approved ${item.hoursWorked} hours for ${item.employee.displayName}`)
    setApprovalModalOpen(true)
    setItemToApprove(item)

    axios({
      method: 'post',
      url: `${props.url}/api/employees/timesheetApproval`,
      data: {
        user: props.user,
        item: item,
        type: 'approve'
      }
    })
      .then(({ data }) => {
            props.notification({
              type: 'success',
              title: 'Shift Approved',
              message: 'Shift Approved...Great Job!'
            })
      // console.log(data)
        // setLeads(data)

      }).catch(function (err) {
      // console.log('Error Undoing this', err)
      });

  }

  const updateFromApproval = (type) => {
    setApprovalModalOpen(false)
    if (type === 'approved') {
    // console.log('Approve this: ', itemToApprove)
      let sheets = timesheets
      const index = sheets.findIndex(item => (item._id.toString() === itemToApprove._id.toString()))
      if (index > -1) sheets.splice(index, 1)
      setTimesheets(sheets)
      props.notification({
        type: 'success',
        title: 'Shift Approved',
        message: 'Shift Approved...Great Job!'
      })
    }
    if (type === 'cancel') {
    // console.log('Cancel this one!!!')
      props.notification({
        type: 'warning',
        title: 'Approval Cancelled',
        message: 'We Have Stopped That Approval'
      })
    }
    setItemToApprove({})

  }

  const undoApproval = async (item) => {
    axios({
      method: 'post',
      url: `${props.url}/api/employees/timesheetApproval`,
      data: {
        user: props.user,
        item: item,
        type: 'undo'
      }
    })
      .then(({ data }) => {
      // console.log(data)
        // setLeads(data)
      }).catch(function (err) {
      // console.log('Error Undoing this', err)
      });
  }

  const handleUndoItem = () => {
    setApprovalModalOpen(false)
  // console.log('Undo:', itemToApprove)
    undoApproval(itemToApprove)
    let sheets = timesheets
    sheets.unshift(itemToApprove)
  // console.log('Sheets:', sheets)
    // const index = sheets.findIndex(item => (item._id.toString() === itemToApprove._id.toString()))
    // if (index > -1) sheets.splice(index, 1)
    setTimesheets(sheets)
    setItemToApprove({})
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setApprovalModalOpen(false)
  };

  const handleRunApprovedTimesheetsReport = () => {
    axios({
      method: 'post',
      url: `${props.url}/api/employees/runApprovedReport`,
      data: {
        user: props.user
      }
    })
      .then(({ data }) => {
        console.log(data)
        // setLeads(data)
        // setTimesheets(data)
        if (data && data.error) {
          props.notification({
            type: 'warning',
            message: data.message,
            title: 'Something Went Wrong'
          })
        } else {
          props.notification({
            type: 'success',
            message: 'Opening Data Now',
            title: 'Timesheets Downloaded'
          })
          let newBlob = new Blob([data], {type: "text/csv"})
          // console.log(newBlob)
          const objectURL = window.URL.createObjectURL(newBlob);
          // console.log(data)
          var link = document.createElement('a');
          link.href = objectURL;
          let fileName = 'Approved_Payroll'.replace(/[^a-z0-9]/gi, '_').toLowerCase()
          link.download=`${fileName}.csv`;
          link.click();
        }

      }).catch(function (err) {
      // console.log('Error Undoing this', err)
      });
  }

  const handleSaveShift = (d) => {
    console.log('Save new shift', d)
    let clockIn =  moment(d.clockIn, 'YYYY-MM-DDThh:mm').local()
    let clockOut = moment(d.clockOut, 'YYYY-MM-DDThh:mm').local()
    console.log('Clock IN', clockIn)
    console.log('Clock out: ', clockOut)
    let hoursWorked = Math.abs(clockIn - clockOut) / 36e5
    hoursWorked = parseFloat(hoursWorked).toFixed(2)
    const obj = {
      clockIn: clockIn._d,
      clockOut: clockOut._d,
      hoursWorked,
      employee: d.employeeId
    }
    console.log('Object to send', obj)
    axios({
      method: 'post',
      url: `${props.url}/api/employees/addNewShift`,
      data: obj
    })
      .then(({ data }) => {
        console.log(data)
        // setLeads(data)
        if (data?.shift?._id) {
          let newSheets = [
            data.shift,
            ...timesheets
          ]
          setTimesheets(newSheets)
        }
        props.notification({
          title: 'Shift Created',
          message: 'That shift now exists',
          type: 'success'
        })
      }).catch(function (err) {
        console.log('Error adding shift', err)
      });
  }

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
        open={approvalModalOpen}
        autoHideDuration={5000}
        message={snackMessage}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={handleUndoItem}>
              UNDO
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <TimeEditModal open={timeEditOpen} data={timeToEdit} updateParent={updateFromTimeEdit} />
      {/* <ApprovingModal open={approvalModalOpen} shift={itemToApprove} updateParent={updateFromApproval} /> */}
      <Container>
        <FilterColumnContainer>
          <FilterColumn>
            <FilterTitle>Choose Settings</FilterTitle>
            <FilterData>Pay Period ({state.startingDate || ''} - {state.endingDate || ''})</FilterData>
            <FilterData></FilterData>
            <div>
              <Button variant="contained" color="secondary" onClick={() => setIsModalOpen(true)}>Add Shift</Button>
              <AddShiftModal
                open={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                employees={props.employees}
                onSave={handleSaveShift}
              />
            </div>
            <FilterData></FilterData>
            <Button
              variant="contained"
              color="primary"
              disabled={!timesheets || !timesheets.length}
              onClick={handleRunApprovedTimesheetsReport}
            >Run Approved Sheets</Button>
          </FilterColumn>
          
          
        </FilterColumnContainer>
        <MainDataView>
          <Table>
            {/* <TableHead>
              <TableRow>
                <TableCell component="th" id="Employee" scope="row" padding="none">Employee Name</TableCell>
                <TableCell component="th" id="Date" scope="row" padding="none">Date</TableCell>
                <TableCell component="th" id="CheckIn" scope="row" padding="none">Check-In</TableCell>
                <TableCell component="th" id="ClockIn" scope="row" padding="none">Clock-In</TableCell>
                <TableCell component="th" id="ClockOut" scope="row" padding="none">Clock-Out</TableCell>
                <TableCell component="th" id="Hours Worked" scope="row" padding="none">Hours Worked</TableCell>
                <TableCell component="th" id="Percent Assigned" scope="row" padding="none">% Accounted</TableCell>
                <TableCell component="th" id="Confirm" scope="row" padding="none">Approve</TableCell>
                <TableCell component="th" id="Archive" scope="row" padding="none">Archive</TableCell>
              </TableRow>
            </TableHead> */}
            <EnhancedTableHead
              classes={classes}
              // numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={timesheets.length}
            />
            <TableBody >
            { loading ? (
              <CircularProgress size={36} />
             ) : (
             stableSort(timesheets, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
            // <TableBody>
            //  { loading ? (
            //   <CircularProgress size={36} />
            //  ) : ( timesheets.map((row, index) => {
                const labelId = `enhanced-table-item-${index}`;
                return (
                  <ShiftLine 
                    key={row._id}
                    even={checkIfEven(index)}
                    onDoubleClick={() => handleEditRow(row, index)}
                  >
                    <ShiftCell component="td" id="DisplayName" scope="row" padding="none">{(row.employee && row.employee.displayName) ? row.employee.displayName : ''}</ShiftCell>
                    <ShiftCell component="td" id="Created" scope="row" padding="none">{moment(row.created_at).format('ddd MM/DD/YYYY')}</ShiftCell>
                    <ShiftCell component="td" id="CheckedIn" scope="row" padding="none">{row.checkIn ? timeFormat(row.checkIn) : ''}</ShiftCell>
                    <ShiftCell component="td" id="ClockedIn" scope="row" padding="none">{row.clockIn ? timeFormat(row.clockIn) : ''} </ShiftCell>
                    <ShiftCell component="td" id="ClockedOut" scope="row" padding="none">{row.clockOut ? timeFormat(row.clockOut) : ''}</ShiftCell>
                    <ShiftCell component="td" id="RoundedHours" scope="row" padding="none">{row.hoursWorked ? hoursRounded(row.hoursWorked) : ''}</ShiftCell>
                    <ShiftCell component="td" id="PrecentAssigned" scope="row" padding="none">
                      <BorderLinearProgress style={{width: '100px'}} variant="determinate" value={row.hoursWorkedVsClockedIn ? row.hoursWorkedVsClockedIn : 0} />
                      {/* {row.hoursWorkedVsClockedIn ? `${row.hoursWorkedVsClockedIn}%` : '0%'} */}
                    </ShiftCell>
                    <ShiftCell component="td" id="ConfirmAction" scope="row" padding="none">
                      { row.approved ? '' : (
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          startIcon={<ThumbsUp />}
                          onClick={() => handleApproveItem(row)}
                          >Approve</Button>
                      )}
                    </ShiftCell>
                    <ShiftCell component="td" id="ArchiveAction" scope="row" padding="none">
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          startIcon={<DeleteForeverIcon />}
                          onClick={() => handleArchive(row)}
                          >Delete</Button>
                    </ShiftCell>
                  </ShiftLine>
                )
              }))}
            </TableBody>
          </Table>
          <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100, 500]}
          component="div"
          count={timesheets.length}
          rowsPerPage={rowsPerPage}
          page={page}
          // onChangePage={handleChangePage}
          onPageChange={handleChangePage}
          // onChangeRowsPerPage={handleChangeRowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </MainDataView>
      </Container>
    </div>
  )
}


const useStyles = makeStyles(theme => ({
  root: {
    width: '95%',
    margin: 10
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'
  },
  rowPad: {
    height: '40px !important',
    // padding: 'px'
  }
}));

const mapStateToProps = (state) => {
// console.log('State Available: ', state)
  return {
    url: state.url,
    authenticated: state.authenticated,
    notification: state.notification,
    employees: state.employees
  }
}

export default connect(mapStateToProps)(Timesheet)