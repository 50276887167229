// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registration_registration__m6Jum {
  margin: 20px;
  text-align: left;
}

.registration_title__FkeOk {
  padding-left: 30px;
  padding-top: 20px;
}

.registration_content__OtKMT {
  margin: 10px;
  padding: 20px;
  padding-top: 0;
}

.registration_logo__XUEiJ {
  margin-left: 0;
  margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Registration/registration.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,iBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,cAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;AACF","sourcesContent":[".registration{\n  margin: 20px;\n  text-align: left;\n}\n\n.title{\n  padding-left: 30px;\n  padding-top: 20px;\n}\n\n.content{\n  margin: 10px;\n  padding: 20px;\n  padding-top: 0;\n}\n\n.logo{\n  margin-left: 0;\n  margin-right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"registration": `registration_registration__m6Jum`,
	"title": `registration_title__FkeOk`,
	"content": `registration_content__OtKMT`,
	"logo": `registration_logo__XUEiJ`
};
export default ___CSS_LOADER_EXPORT___;
