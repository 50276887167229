// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logout_logout__UfhVf {
  margin: 50px;
  text-align: center;
}

.logout_circle__epv\\+G {
  margin-left: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/Logout/logout.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".logout{\n  margin: 50px;\n  text-align: center;\n}\n\n.circle{\n  margin-left: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logout": `logout_logout__UfhVf`,
	"circle": `logout_circle__epv+G`
};
export default ___CSS_LOADER_EXPORT___;
