// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activationHash_activationHash__JHDw1 {
  margin: 50px;
  text-align: center;
}

.activationHash_title__d0kRQ {
  padding-top: 20px;
}

.activationHash_content__Oqesw {
  margin: 10px;
  padding: 20px;
}

.activationHash_logo__5kmxB {
  margin-left: 0;
  margin-right: 0;
}

.activationHash_loader__jkpvy {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/ActivationHash/activationHash.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".activationHash{\n  margin: 50px;\n  text-align: center;\n}\n\n.title{\n  padding-top: 20px;\n}\n\n.content{\n  margin: 10px;\n  padding: 20px;\n}\n\n.logo{\n  margin-left: 0;\n  margin-right: 0;\n}\n\n.loader{\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activationHash": `activationHash_activationHash__JHDw1`,
	"title": `activationHash_title__d0kRQ`,
	"content": `activationHash_content__Oqesw`,
	"logo": `activationHash_logo__5kmxB`,
	"loader": `activationHash_loader__jkpvy`
};
export default ___CSS_LOADER_EXPORT___;
