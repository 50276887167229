// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recovery_recover__OL9iR {
  margin: 20px;
  text-align: left;
}

.recovery_title__e6SQ6 {
  padding-left: 30px;
  padding-top: 20px;
}

.recovery_content__oRCo6 {
  margin: 10px;
  padding: 20px;
  padding-top: 0;
}

.recovery_logo__n7Y4P {
  margin-left: 0;
  margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Recovery/recovery.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,iBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,cAAA;AACF;;AAEA;EACE,cAAA;EACA,eAAA;AACF","sourcesContent":[".recover{\n  margin: 20px;\n  text-align: left;\n}\n\n.title{\n  padding-left: 30px;\n  padding-top: 20px;\n}\n\n.content{\n  margin: 10px;\n  padding: 20px;\n  padding-top: 0;\n}\n\n.logo{\n  margin-left: 0;\n  margin-right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recover": `recovery_recover__OL9iR`,
	"title": `recovery_title__e6SQ6`,
	"content": `recovery_content__oRCo6`,
	"logo": `recovery_logo__n7Y4P`
};
export default ___CSS_LOADER_EXPORT___;
