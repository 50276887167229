// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation_link__-abeQ {
  margin-left: 20px;
  margin-right: 20px;
  text-decoration: none;
  cursor: pointer;
}

.navigation_white__FkZ2x {
  color: white;
}

.navigation_black__mTlhN {
  color: black;
}

.navigation_flexGrow__G6BV0 {
  flex-grow: 1;
}

.navigation_theme__dxJNL {
  top: -10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Navigation/navigation.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;EACA,qBAAA;EACA,eAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,YAAA;AACF;;AAEA;EACE,UAAA;AACF","sourcesContent":[".link{\n  margin-left: 20px;\n  margin-right: 20px;\n  text-decoration: none;\n  cursor: pointer;\n}\n\n.white{\n  color: white;\n}\n\n.black{\n  color: black;\n}\n\n.flexGrow{\n  flex-grow: 1;\n}\n\n.theme{\n  top: -10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `navigation_link__-abeQ`,
	"white": `navigation_white__FkZ2x`,
	"black": `navigation_black__mTlhN`,
	"flexGrow": `navigation_flexGrow__G6BV0`,
	"theme": `navigation_theme__dxJNL`
};
export default ___CSS_LOADER_EXPORT___;
