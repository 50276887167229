// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error_error__n5c5r {
  margin: 20px;
}

.error_link__OeJnf {
  cursor: pointer;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/Error/error.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,eAAA;EACA,0BAAA;AACF","sourcesContent":[".error{\n  margin: 20px;\n}\n\n.link{\n  cursor: pointer;\n  text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error": `error_error__n5c5r`,
	"link": `error_link__OeJnf`
};
export default ___CSS_LOADER_EXPORT___;
