

import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Checkbox,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Add as AddIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '90vw',
      maxWidth: '90vw',
    },
  },
  table: {
    marginTop: theme.spacing(2),
  },
  totalRow: {
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
  },
  qtyInput: {
    width: '60px',
  },
  typeSelect: {
    minWidth: '150px',
  },
  descriptionInput: {
    minWidth: '200px',
  },
  costPerInput: {
    width: '80px',
  },
  pricePerInput: {
    width: '80px',
  },
  addButton: {
    marginTop: theme.spacing(2),
  },
}));

const initialLineItem = {
  qty: '',
  type: '',
  description: '',
  costPer: '',
  pricePer: '',
  taxable: false,
  laborType: '',
};

const defaultRates = {
  labor: { price: 25, cost: 20 },
  install: { price: 43, cost: 35 },
  supervisor: { price: 23, cost: 18 },
  sprayTech: { price: 58, cost: 45 },
  arborist: { price: 50, cost: 40 },
  treeWorker: { price: 35, cost: 28 },
  operator: { price: 69, cost: 55 },
  irrigationTech: { price: 45, cost: 36 },
};

const lineItemTypes = ['Labor', 'Materials', 'Subcontractor', 'Equipment', 'Supervision'];

const laborTypes = [
  { value: 'labor', display: 'General Labor' },
  { value: 'install', display: 'Install' },
  { value: 'supervisor', display: 'Supervisor' },
  { value: 'sprayTech', display: 'Spray Tech' },
  { value: 'arborist', display: 'Arborist' },
  { value: 'treeWorker', display: 'Tree Worker' },
  { value: 'operator', display: 'Operator' },
  { value: 'irrigationTech', display: 'Irrigation Tech' }
];

const handleFocus = (event) => event.target.select();

const LineItemModal = ({ open, client, onClose, onSubmit, initialLineItems = [] }) => {
  const classes = useStyles();
  const [lineItems, setLineItems] = useState(initialLineItems);
  const [editingIndex, setEditingIndex] = useState(null);
  const [laborTypeModalOpen, setLaborTypeModalOpen] = useState(false);
  const [currentLaborItem, setCurrentLaborItem] = useState(null);

  useEffect(() => {
    if (initialLineItems.length) setLineItems(initialLineItems);
  }, [initialLineItems]);

  const getRate = (rateKey, rateType) => {
    if (client.laborRates && client.laborRates[rateKey] && client.laborRates[rateKey] !== "") {
      return client.laborRates[rateKey];
    }
    return defaultRates[rateKey]?.[rateType] || 0;
  };

  const handleInputChange = (index, field, value) => {
    setLineItems((prev) =>
      prev.map((item, i) => {
        if (i === index) {
          const updatedItem = { ...item, [field]: value };
          
          if (field === 'type' && value === 'Labor') {
            setCurrentLaborItem(index);
            setLaborTypeModalOpen(true);
          } else if (field === 'type' && value !== 'Labor') {
            updatedItem.laborType = '';
            updatedItem.costPer = '';
            updatedItem.pricePer = '';
          }
          
          return updatedItem;
        }
        return item;
      })
    );
  };

  const handleLaborTypeSelect = (laborType) => {
    setLineItems((prev) =>
      prev.map((item, i) => {
        if (i === currentLaborItem) {
          return {
            ...item,
            laborType: laborType.value,
            description: `${laborType.display} - ${item.description || ''}`.trim(),
            costPer: getRate(laborType.value, 'cost'),
            pricePer: getRate(laborType.value, 'price'),
          };
        }
        return item;
      })
    );
    setLaborTypeModalOpen(false);
  };

  const handleAdd = () => {
    setLineItems((prev) => [...prev, { ...initialLineItem }]);
    setEditingIndex(lineItems.length);
  };

  const handleSave = (index) => {
    setEditingIndex(null);
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
  };

  const handleDelete = (index) => {
    setLineItems((prev) => prev.filter((_, i) => i !== index));
  };

  const calculateTotal = (item) =>
    parseFloat(item.qty) * parseFloat(item.pricePer) || 0;

  const calculateGrandTotal = () =>
    lineItems.reduce((sum, item) => sum + calculateTotal(item), 0);

  const isItemComplete = (item) =>
    item.qty && item.type && item.description && item.costPer && item.pricePer &&
    (item.type !== 'Labor' || item.laborType);

  const handleSubmit = () => {
    onSubmit(lineItems);
    onClose();
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth className={classes.dialog}>
        <DialogTitle>Line Item Builder</DialogTitle>
        <DialogContent>
          <TableContainer className={classes.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Cost Per</TableCell>
                  <TableCell>Price Per</TableCell>
                  <TableCell>Taxable</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {lineItems.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <TextField
                        type="number"
                        value={item.qty}
                        onChange={(e) => handleInputChange(index, 'qty', e.target.value)}
                        disabled={editingIndex !== index}
                        onFocus={handleFocus}
                        className={classes.qtyInput}
                      />
                    </TableCell>
                    <TableCell>
                      <Select
                        value={item.type}
                        onChange={(e) => handleInputChange(index, 'type', e.target.value)}
                        disabled={editingIndex !== index}
                        className={classes.typeSelect}
                      >
                        <MenuItem value="" disabled>Select Type</MenuItem>
                        {lineItemTypes.map((type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={item.description}
                        onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                        disabled={editingIndex !== index}
                        onFocus={handleFocus}
                        className={classes.descriptionInput}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        onFocus={handleFocus}
                        value={item.costPer}
                        onChange={(e) => handleInputChange(index, 'costPer', e.target.value)}
                        disabled={editingIndex !== index}
                        className={classes.costPerInput}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={item.pricePer}
                        onFocus={handleFocus}
                        onChange={(e) => handleInputChange(index, 'pricePer', e.target.value)}
                        disabled={editingIndex !== index}
                        className={classes.pricePerInput}
                      />
                    </TableCell>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.taxable}
                            onChange={(e) => handleInputChange(index, 'taxable', e.target.checked)}
                            disabled={editingIndex !== index}
                          />
                        }
                        label=""
                      />
                    </TableCell>
                    <TableCell>
                      ${calculateTotal(item).toFixed(2)}
                    </TableCell>
                    <TableCell>
                      {editingIndex === index ? (
                        <IconButton onClick={() => handleSave(index)} disabled={!isItemComplete(item)}>
                          <SaveIcon />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => handleEdit(index)}>
                          <EditIcon />
                        </IconButton>
                      )}
                      <IconButton onClick={() => handleDelete(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow className={classes.totalRow}>
                  <TableCell colSpan={6} align="right">
                    Grand Total:
                  </TableCell>
                  <TableCell>${calculateGrandTotal().toFixed(2)}</TableCell>
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAdd}
            startIcon={<AddIcon />}
            className={classes.addButton}
          >
            Add New Line Item
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={laborTypeModalOpen} onClose={() => setLaborTypeModalOpen(false)}>
        <DialogTitle>Select Labor Type</DialogTitle>
        <DialogContent>
          {laborTypes.map((type) => (
            <Button key={type.value} onClick={() => handleLaborTypeSelect(type)} fullWidth>
              {type.display}
            </Button>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LineItemModal;