// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.DraftEditor-root {
  background-color: rgba(233, 233, 232, 0.103);
  min-height: 200px;
  max-height: 500px;
  width: '100%';
  overflow-y: auto;
  border: 2.5px solid #ddd;
}
div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  min-height: 200px;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Projects/milestoneEditor.css"],"names":[],"mappings":"AAAA;EACE,4CAA4C;EAC5C,iBAAiB;EACjB,iBAAiB;EACjB,aAAa;EACb,gBAAgB;EAChB,wBAAwB;AAC1B;AACA;;EAEE,iBAAiB;EACjB,YAAY;AACd","sourcesContent":["div.DraftEditor-root {\n  background-color: rgba(233, 233, 232, 0.103);\n  min-height: 200px;\n  max-height: 500px;\n  width: '100%';\n  overflow-y: auto;\n  border: 2.5px solid #ddd;\n}\ndiv.DraftEditor-editorContainer,\ndiv.public-DraftEditor-content {\n  min-height: 200px;\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
