// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile_profile__3-vPC {
  margin: 20px;
}

.profile_button__Ez5aV {
  margin-right: 20px !important;
}

.profile_link__1VrWr {
  color: inherit;
  text-decoration: none;
  cursor: inherit;
}

.profile_card__8u7YB {
  width: 400px;
}`, "",{"version":3,"sources":["webpack://./src/components/Profile/profile.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,6BAAA;AACF;;AAEA;EACE,cAAA;EACA,qBAAA;EACA,eAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".profile{\n  margin: 20px;\n}\n\n.button{\n  margin-right: 20px !important;\n}\n\n.link{\n  color: inherit;\n  text-decoration: none;\n  cursor: inherit;\n}\n\n.card{\n  width: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile": `profile_profile__3-vPC`,
	"button": `profile_button__Ez5aV`,
	"link": `profile_link__1VrWr`,
	"card": `profile_card__8u7YB`
};
export default ___CSS_LOADER_EXPORT___;
