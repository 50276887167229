// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* editorStyles.css */
.myEditorClassName .public-DraftStyleDefault-block {
  font-family: 'Times New Roman', Times, serif;
}`, "",{"version":3,"sources":["webpack://./src/components/Common/SendToCustomer/editorStyles.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;EACE,4CAA4C;AAC9C","sourcesContent":["/* editorStyles.css */\n.myEditorClassName .public-DraftStyleDefault-block {\n  font-family: 'Times New Roman', Times, serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
