// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_home__Qucyc {
  margin: 20px;
}

.home_card__NcJF\\+ {
  width: 95%;
  margin-bottom: 2%;
}

.home_search__UJODi {
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/components/Home/home.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,UAAA;EACA,iBAAA;AACF;;AACA;EACE,kBAAA;AAEF","sourcesContent":[".home{\n  margin: 20px;\n}\n\n.card{\n  width: 95%;\n  margin-bottom: 2%;\n}\n.search {\n  position: relative;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home": `home_home__Qucyc`,
	"card": `home_card__NcJF+`,
	"search": `home_search__UJODi`
};
export default ___CSS_LOADER_EXPORT___;
